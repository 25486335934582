<template>
  <b-card-code>
    <b-row>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Class') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('class')"
            rules="required"
        >
          <select v-model="sclasses_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
              {{ cls.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Version') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('version')"
            rules="required"
        >
          <select v-model="sversion_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(version, key) in versions" :value="version.id" :key="key">
              {{ version.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Shift') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('shift')"
            rules="required"
        >
          <select v-model="sshift_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">
              {{ shift.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Student Group') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('section')"
            rules="required"
        >
          <select v-model="sstudent_group_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">
              {{ sgrp.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Section') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('section')"
            rules=""
        >
          <select v-model="ssection_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(section, key) in sections" :value="section.id" :key="key">
              {{ section.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="4">
        <b-card-text>
          <span>{{ $t('Select Academic Year') }} </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            :name="$t('academic year')"
            rules="required"
        >
          <select v-model="sacademic_year_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
            <option :value="null">{{ $t('Select One') }}</option>
            <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">
              {{ aYear.year }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end mt-2">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="students"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="props.row.image?imgShow(props.row.image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ $store.getters.classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ $store.getters.versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ $store.getters.wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ $store.getters.student_groupByID(props.row.student_group_id) }}
        </span>

        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ $store.getters.academic_yearByID(props.row.academic_year_id) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-form-checkbox v-if="$can('promotion','Student')" class="btn btn-primary"
                               v-ripple.400="'rgba(113, 102, 240, 0.15)'" 
                               name="selectedStudent[]" v-model="selectedStudent" checked="true" :value="props.row.id"
              ></b-form-checkbox>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <div v-if="$can('promotion','Student') && selectedStudent.length>0" class="custom-search d-flex mt-2" style="float: right">
      <b-button variant="primary" @click="showModal()">
        {{ $t('Promotion') }}
      </b-button>
    </div>
    <!-- modal login-->
    <b-modal ref="my-modal" hide-footer size="lg" :title="$t('Student Promotion')">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="update_student" @submit="validationForm" action="javascript:void(0)">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Class') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('class')"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                    {{ cls.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Version') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('version')"
                  rules="required"
              >
                <select name="version_id" v-model="version_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">
                    {{ version.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Shift') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('shift')"
                  rules="required"
              >
                <select name="shift_id" v-model="shift_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">
                    {{ shift.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Student Group') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('section')"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">
                    {{ sgrp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Section') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('section')"
                  rules="required"
              >
                <select name="section_id" v-model="section_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(section, key) in sections" :value="section.id" :key="key">
                    {{ section.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Academic Year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('academic year')"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">
                    {{ aYear.year }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-col>
            <b-button class="mt-2" variant="primary" type="submit">{{ $t('Promotion') }}</b-button>
          </b-col>
        </form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect, BCardText,
  BRow, BCol,BFormCheckbox
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required, integer, email,
} from '@validations'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";

export default {
  name: 'ClassPromotion',
  components: {
    BCardCode, ValidationProvider, ValidationObserver,
    VueGoodTable, BCardText, BRow, BCol,
    BPagination,BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required, integer, email,
      pageLength: 10,
      dir: false,
      model_mode: 'add',
      student_photo: null,
      all_sections: [],
      filter_sections: [],
      name: '',
      selected_row: {},
      student: {},
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Photo'),
          field: 'image',
        },
        {
          label: this.$t('Student Number'),
          field: 'cadet_no',
        },
        {
          label: this.$t('Name'),
          field: 'userable.name',
        },
        {
          label: this.$t('Class'),
          field: 'classes_id',
        },
        {
          label: this.$t('Student Group'),
          field: 'student_group_id',
        },
        {
          label: this.$t('Academic Year'),
          field: 'academic_year_id',
        },
        {
          label: this.$t('Select'),
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        1: this.$t('active'),
        0: this.$t('deactivate'),
      },
      classes_id:null,
      version_id:null,
      shift_id:null,
      student_group_id:null,
      section_id:null,
      academic_year_id:null,
      sclasses_id:null,
      sversion_id:null,
      sshift_id:null,
      sstudent_group_id:null,
      ssection_id:null,
      sacademic_year_id:null,
      selectedStudent:[],
    }
  },
  methods: {
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.update_student);
          for (var i = 0; i < this.selectedStudent.length; i++) {
            data.append('selectedStudent[]', this.selectedStudent[i]);
          }
          apiCall.post(`/student/class/promotion`, data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.hideModal();
              this.$store.dispatch('GET_ALL_STUDENT');
              this.$refs.simpleRules.reset();
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    students(){
      let std = this.$store.getters.students.filter((item)=>item.is_active===1 && item.classes_id===this.sclasses_id
      && item.version_id===this.sversion_id && item.shift_id===this.sshift_id && item.student_group_id===this.sstudent_group_id
      && item.academic_year_id===this.sacademic_year_id);
      if(this.ssection_id){
        std=std.filter((item)=>item.section_id===this.ssection_id);
      }
      return std;
    },
    ...mapGetters([
      'classes', 'classByID', 'versions', 'versionByID', 'shifts', 'shiftByID',
      'student_groups', 'student_groupByID', 'roles', 'roleByID',
      'academic_years', 'academic_yearByID', 'active_religions', 'religions', 'religionById',
      'genders', 'genderById', 'blood_groups', 'bloodGroupById', 'wings', 'wingByID', 'sections', 'sectionById']),
  },
  created() {
    if (this.students.length < 1) this.$store.dispatch('GET_ALL_STUDENT');
    if (this.classes.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.religions.length < 1) this.$store.dispatch('GET_ALL_RELIGION');
    if (this.blood_groups.length < 1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
    if (this.versions.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    if (this.shifts.length < 1) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.roles.length < 1) this.$store.dispatch('GET_ALL_ROLE');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
    if (this.sections.length < 1) this.$store.dispatch('GET_ALL_SECTION');
  },
  watch: {

  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
